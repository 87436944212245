import React, { useEffect, useState } from "react";
// import { usePDF } from "react-to-pdf";
import axios from "../../helpers/axios";
import { Tab, Tabs } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

export default function CollectionReport() {
  const [activeTab, setActiveTab] = useState(1);
  //   const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const [activeTabSport, setActiveTabSport] = useState(0);
  const [lenaHai, setLenaHai] = useState([]);
  const [denaHai, setDenaHai] = useState([]);
  const [clearHai, setClearHai] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
const [selectedDW, setSelectedDW] = useState({}); // Stores the selected state for each row
const [editingCreditLimit, setEditingCreditLimit] = useState({}); // Track which row is in edit mode for credit limit
const [creditLimitValues, setCreditLimitValues] = useState({}); // Stores the credit limit values

const handleDWClick = (id, type) => {
  setSelectedDW((prev) => ({
    ...prev,
    [id]: prev[id] === type ? null : type, // Toggle between D, W, or null
  }));
};

const handleEditCreditLimit = (id) => {
  setEditingCreditLimit((prev) => ({
    ...prev,
    [id]: true,
  }));
};

const handleConfirmCreditLimit = (id) => {
  setEditingCreditLimit((prev) => ({
    ...prev,
    [id]: false,
  }));
};

const handleCancelCreditLimit = (id) => {
  setEditingCreditLimit((prev) => ({
    ...prev,
    [id]: false,
  }));
};

const columns = [
  {
    field: "user_id",
    headerName: "User ID",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "pl",
    headerName: "P & L",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "dw",
    headerName: "DW",
    width: 150,
    disableColumnMenu: true,
    renderCell: (params) => (
      <div style={{ display: "flex", gap: "8px" }}>
        <button
          style={{
            backgroundColor: selectedDW[params.id] === "D" ? "red" : "#f0f0f0",
            border: "1px solid #ccc",
            padding: "6px 12px",
            borderRadius: "4px",
            cursor: "pointer",
            color: selectedDW[params.id] === "D" ? "#fff" : "#000",
            fontWeight: "bold",
          }}
          onClick={() => handleDWClick(params.id, "D")}
        >
          D
        </button>
        <button
          style={{
            backgroundColor:
              selectedDW[params.id] === "W" ? "green" : "#f0f0f0",
            border: "1px solid #ccc",
            padding: "6px 12px",
            borderRadius: "4px",
            cursor: "pointer",
            color: selectedDW[params.id] === "W" ? "#fff" : "#000",
            fontWeight: "bold",
          }}
          onClick={() => handleDWClick(params.id, "W")}
        >
          W
        </button>
      </div>
    ),
  },
  {
    field: "transactions",
    headerName: "Transactions",
    width: 200,
    disableColumnMenu: true,
    renderCell: (params) => (
      <input
        type="text"
        style={{
          width: "60%",
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
        disabled={!selectedDW[params.id]} // Enable only if D or W is selected
        value={params.value}
      />
    ),
  },
  {
    field: "player_balance",
    headerName: "Player Balance",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "credit_limit",
    headerName: "Credit Limit",
    width: 200,
    disableColumnMenu: true,
    renderCell: (params) => {
      const isEditing = editingCreditLimit[params.id] || false;
      const value = creditLimitValues[params.id] || params.value;

      return (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          {isEditing ? (
            <>
              <input
                type="text"
                value={value}
                style={{
                  width: "100px",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                onChange={(e) =>
                  setCreditLimitValues((prev) => ({
                    ...prev,
                    [params.id]: e.target.value,
                  }))
                }
              />
              <button
                style={{
                  padding: "6px 12px",
                  backgroundColor: "green",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleConfirmCreditLimit(params.id)}
              >
                ✔
              </button>
              <button
                style={{
                  padding: "6px 12px",
                  backgroundColor: "red",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleCancelCreditLimit(params.id)}
              >
                ✖
              </button>
            </>
          ) : (
            <>
              <span>{value}</span>
              <button
                style={{
                  padding: "4px",
                  backgroundColor: "#f0f0f0",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleEditCreditLimit(params.id)}
              >
                ✏️
              </button>
            </>
          )}
        </div>
      );
    },
  },
  {
    field: "remark",
    headerName: "Remark",
    width: 150,
    disableColumnMenu: true,
    renderCell: (params) => (
      <input
        type="text"
        value={params.value}
        style={{
          width: "80%",
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />
    ),
  },
  {
    field: "available_balance",
    headerName: "Available Balance",
    width: 150,
    disableColumnMenu: true,
  },
];

const rows = [
  // Example data for the table
  {
    id: 1,
    user_id: "User1",
    pl: "100",
    player_balance: "500",
    credit_limit: "1000",
    remark: "",
    available_balance: "600",
    transactions: "",
  },
  {
    id: 2,
    user_id: "User2",
    pl: "-50",
    player_balance: "300",
    credit_limit: "500",
    remark: "",
    available_balance: "200",
    transactions: "",
  },
];

useEffect(() => {
  const getCollection = async () => {
    try {
      const res = await axios.get("/users/getCollectionReport", {
        params: { user_id: localStorage.getItem("_id") },
      });
      if (res.data.status) {
        const data = res.data.dataobj;
        setAllCollections(data);
        setDenaHai(data.filter((i) => i.amount > 0));
        setLenaHai(data.filter((i) => i.amount < 0));
        setClearHai(data.filter((i) => i.amount == 0));
      }
    } catch (er) {
      console.log(er);
    }
  };
  getCollection();
}, []);
//   const actionsMemo = React.useMemo(
//     () => (
//       <div style={{ display: "flex", fontSize: "1rem" }}>
//         <CSVGenerator columns={columns} data={data} />
//         <DownloadPdf
//           columns={columns}
//           data={data}
//           tableName={"Table Name"}
//         />
//       </div>
//     ),
//     []
//   );

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const returnCurrentTabTable = () => {
  const generateRowId = (row, index) => row._id || `${row.user_id}-${index}`;

  if (activeTab == 1 && activeTabSport == 0)
    return (
      <div style={{ height: 800, width: "100%" }}>
        <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
          PAYMENT RECEIVING FROM (Lena hai)
        </div>

        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row, index) => generateRowId(row, index)} // Generate unique id if _id doesn't exist
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          pageSizeOptions={[10, 20, 30]}
          autoHeight
          disableSelectionOnClick
        />
      </div>
    );
  else if (activeTab == 1 && activeTabSport == 1)
    return (
      <div style={{ height: 800, width: "100%" }}>
        <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
          PAYMENT PAID TO (Dena hai)
        </div>

        <DataGrid
          rows={denaHai}
          columns={columns}
          getRowId={(row, index) => generateRowId(row, index)} // Generate unique id if _id doesn't exist
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 30]}
          slots={{
            toolbar: CustomToolbar,
          }}
          autoHeight
          disableSelectionOnClick
        />
      </div>
    );
};

// const returnCurrentTabTable = () => {
//   if (activeTab == 1 && activeTabSport == 0)
//     return (
//       <div style={{ height: 800, width: "100%" }}>
//         <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
//           PAYMENT RECEIVING FROM (Lena hai)
//         </div>

//         <DataGrid
//           rows={rows}
//           columns={columns}
//           getRowId={(row) => row._id}
//           initialState={{
//             pagination: {
//               paginationModel: { page: 0, pageSize: 10 },
//             },
//           }}
//           slots={{
//             toolbar: CustomToolbar,
//           }}
//           pageSizeOptions={[10, 20, 30]}
//           autoHeight
//           disableSelectionOnClick
//         />
//       </div>
//     );
//   else if (activeTab == 1 && activeTabSport == 1)
//     return (
//       <div style={{ height: 800, width: "100%" }}>
//         <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
//           PAYMENT PAID TO (Dena hai)
//         </div>

//         <DataGrid
//           rows={denaHai}
//           columns={columns}
//           getRowId={(row) => row._id}
//           initialState={{
//             pagination: {
//               paginationModel: { page: 0, pageSize: 10 },
//             },
//           }}
//           pageSizeOptions={[10, 20, 30]}
//           slots={{
//             toolbar: CustomToolbar,
//           }}
//           autoHeight
//           disableSelectionOnClick
//         />
//       </div>
//     );
//   // else if (activeTab == 1 && activeTabSport == 2)
//   //   return (
//   //     <div style={{ height: 800, width: "100%" }}>
//   //       <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
//   //         PAYMENT CLAER (Clear hai)
//   //       </div>

//   //       <DataGrid
//   //         rows={clearHai}
//   //         columns={columns}
//   //         getRowId={(row) => row._id}
//   //         initialState={{
//   //           pagination: {
//   //             paginationModel: { page: 0, pageSize: 10 },
//   //           },
//   //         }}
//   //         slots={{
//   //           toolbar: CustomToolbar,
//   //         }}
//   //         pageSizeOptions={[10, 20, 30]}
//   //         autoHeight
//   //         disableSelectionOnClick
//   //       />
//   //     </div>
//   //   );
//   else return <></>;
// };

return (
  <div>
    <div
      style={{
        padding: "16px 0",
        display: "grid",
        placeItems: "center",
        background: "",
        borderRadius: "8px",
        marginBottom: "8px",
      }}
    >
      <Tabs
        value={activeTabSport}
        sx={{
          ".Mui-selected": {
            color: `#fff`,
            outline: "none",
          },
          "&:hover": {
            outline: "none",
          },
        }}
        TabIndicatorProps={{ style: { background: "#896cef" } }}
        aria-label="tabs example"
        onChange={(e, newVal) => {
          setActiveTabSport(newVal);
        }}
      >
        <Tab
          value={0}
          label={
            <span
              style={{ color: activeTabSport == 0 ? "#896cef" : "#808080" }}
            >
              Lena hai
            </span>
          }
        />
        <Tab
          value={1}
          label={
            <span
              style={{ color: activeTabSport == 1 ? "#896cef" : "#808080" }}
            >
              Dena hai
            </span>
          }
        />
        {/* <Tab
            value={2}
            label={
              <span
                style={{ color: activeTabSport == 2 ? "#896cef" : "#808080" }}
              >
                Clear hai
              </span>
            }
          /> */}
      </Tabs>
    </div>
    <div>
      {/* <button style={{color:"red"}} onClick={() => generatePDF(getTargetElement, options)}>Download PDF</button> */}
      <div id="content-id">{returnCurrentTabTable()}</div>
    </div>
  </div>
);
}
