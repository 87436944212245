import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import * as yup from "yup";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import NewManageUserForm from "../Manage/partials/NewManageUserForm";
import DwcForm from "./DWCForm/DwcForm";
import MasterUserSettings from "./MasterUserSettings/MasterUserSettings";
import MasterUserLimit from "./MasterUserLimit/MasterUserLimit";
import axios from "../../helpers/axios";
import Swal from "sweetalert2";
import sha256 from "sha256";

export default function MasterTable() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = [
    { field: "_id", headerName: "ID", width: 200, disableColumnMenu: true },
    {
      field: "first_name",
      headerName: "Name",
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: "fix_limit",
      headerName: "Fix Limit",
      type: "number",
      width: 90,
      disableColumnMenu: true,
    },
    {
      field: "my_match_share",
      headerName: "My Share",
      description: "This column has a value getter and is not sortable.",
      width: 160,
      disableColumnMenu: true,
      valueGetter: (value, row) => `${row?.my_match_share || ""}`,
    },
    // {
    //   field: "max_share",
    //   headerName: "Max Share",
    //   width: 160,
    //   disableColumnMenu: true,
    //   valueGetter: (value, row) => `${row?.max_share || ""}`,
    // },
    {
      field: "exposure",
      headerName: "Exposure",
      width: 160,
      disableColumnMenu: true,
      valueGetter: (value, row) => `${row?.exposure || "0"}`,
    },
    // { field: "sno", headerName: "S.No", width: 50, disableColumnMenu: true },
    // {
    //   field: "userId",
    //   headerName: "User Id",
    //   width: 150,
    //   disableColumnMenu: true,
    //   renderCell: (params) => (
    //     <span style={{ color: "#0000FF", textDecoration: "underline" }}>
    //       {params.value}
    //     </span>
    //   ),
    // },
    // {
    //   field: "creditReference",
    //   headerName: "Credit Reference",
    //   width: 130,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "balance",
    //   headerName: "Balance",
    //   width: 130,
    //   disableColumnMenu: true,
    //   renderCell: (params) => (
    //     <span style={{ color: params.value >= 0 ? "green" : "red" }}>
    //       {params.value.toFixed(2)}
    //     </span>
    //   ),
    // },
    // {
    //   field: "playerBalance",
    //   headerName: "Player Balance",
    //   width: 130,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "exposure",
    //   headerName: "Exposure",
    //   width: 130,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "pl",
    //   headerName: "P & L",
    //   width: 130,
    //   disableColumnMenu: true,
    //   renderCell: (params) => (
    //     <span style={{ color: params.value >= 0 ? "green" : "red" }}>
    //       {params.value.toFixed(2)}
    //     </span>
    //   ),
    // },
    {
      field: "agent_block",
      headerName: "User Lock",
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={params.row.agent_block}
          onChange={(e) =>
            params.row.handleCheckboxClick(e, params.row._id, "agent_block")
          }
        />
      ),
    },
    {
      field: "bet_block",
      headerName: "Bet Lock",
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={params.row.bet_block}
          onChange={(e) =>
            params.row.handleCheckboxClick(e, params.row._id, "bet_block")
          }
        />
      ),
    },
    // {
    //   field: "sharing",
    //   headerName: "Sharing %",
    //   width: 100,
    //   disableColumnMenu: true,
    // },
    {
      field: "dwc",
      headerName: "D-W-C",
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <Button variant="text" onClick={() => handleDwcClick(0)}>
            D
          </Button>{" "}
          <Button variant="text" onClick={() => handleDwcClick(1)}>
            W
          </Button>{" "}
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span>
          <span
            style={{ margin: "0 5px", cursor: "pointer" }}
            onClick={() => params.row.handlePasswordChangeClick(params.row._id)}
          >
            🅿️
          </span>
          <span
            style={{ margin: "0 5px", cursor: "pointer" }}
            onClick={() => {
              const userInfo = filteredRows.filter(
                (limit) => limit._id === params.row._id
              );
              params.row.handleLimitClick(userInfo);
            }}
          >
            📋
          </span>
          <span
            style={{ margin: "0 5px", cursor: "pointer" }}
            onClick={() => params.row.handleSettingsClick(params.row.id)}
          >
            ⚙️
          </span>
        </span>
      ),
    },
  ];

  const [openDwcModal, setOpenDwcModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    rowId: null,
    field: "",
  });
  const userDetails = JSON.parse(localStorage.getItem("userProfile"));

  const [passwordModal, setPasswordModal] = useState({
    open: false,
    rowId: null,
  });
  const [limitModal, setLimitModal] = useState({
    open: false,
    info: null,
  });
  const [settingsModal, setSettingsModal] = useState({
    open: false,
    rowId: null,
  });

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [activeTab, setActiveTab] = useState();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDwcClick = (tab) => {
    setActiveTab(tab); // Set the active tab (0 for Deposit, 1 for Withdraw)
    setOpenDwcModal(true);
  };

  // Function to handle closing the modal
  const handleDwcModalClose = () => {
    setOpenDwcModal(false);
  };

  const handleCheckboxClick = (event, rowId, field) => {
    console.log("row ID", rowId);
    event.preventDefault(); // Prevent the checkbox from immediately changing state
    setConfirmDialog({ open: true, rowId, field });
  };

  const handleConfirm = (confirmed) => {
    if (confirmed) {
      const updatedRow = rows?.find((row) => row?._id === confirmDialog?.rowId);

      if (!updatedRow) {
        console.error("Row not found:", confirmDialog?.rowId);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Unable to find the row to update.",
        });
        setConfirmDialog({ open: false, rowId: null, field: "" });
        return;
      }

      const newValue = !updatedRow[confirmDialog?.field];

      setRows(
        rows.map((row) =>
          row._id === confirmDialog.rowId
            ? { ...row, [confirmDialog.field]: newValue }
            : row
        )
      );

      // Prepare the data for axios request
      const updateData = {};
      if (confirmDialog.field === "agent_block") {
        updateData.agent_block = newValue;
      } else if (confirmDialog.field === "bet_block") {
        updateData.bet_block = newValue;
      }

      // Add user ID to the update data
      updateData._id = confirmDialog?.rowId;

      // Send the request if there is data to update
      if (Object.keys(updateData).length > 1) {
        // Ensure _id is present and there's at least one field to update
        axios
          .put("/users/update-user", updateData)
          .then((response) => {
            Swal.fire("User updated successfully");
            // navigate(-1); // Uncomment if needed
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
            // navigate(-1); // Uncomment if needed
          });
      } else {
        console.warn("No fields to update");
      }
    }

    setConfirmDialog({ open: false, rowId: null, field: "" });
  };

  const handlePasswordChangeClick = (rowId) => {
    setPasswordModal({ open: true, rowId });
  };
  const handleLimitClick = (info) => {
    setLimitModal({ open: true, info });
  };
  const handleSettingsClick = (rowId) => {
    setSettingsModal({ open: true, rowId });
  };

  const handlePasswordModalClose = () => {
    setPasswordModal({ open: false, rowId: null });
    setNewPassword("");
    setConfirmPassword("");
  };
  const handleLimitModalClose = () => {
    setLimitModal({ open: false, rowId: null });
  };
  const handleSettingsModalClose = () => {
    setSettingsModal({ open: false, rowId: null });
  };

  const handlePasswordSubmit = async () => {
    const formData = { newPassword, confirmPassword };

    try {
      // Validate form data using Yup schema
      await passwordSchema.validate(formData, { abortEarly: false });
      const encryptedPassword = sha256(newPassword).toString();
      const row = rows.find((row) => row._id === passwordModal.rowId);
      // return row ? row.username : null;
      // If validation passes, handle the password update logic
      // const finalUserData = {
      // ...userDetails,
      // password: encryptedPassword,
      // parent_id: userDetails._id,
      // user_type: user_type,
      // credits: "0",
      // };
      if (newPassword === confirmPassword) {
        axios
          .put("/users/change-password", {
            password: sha256(newPassword),
            username: row.username,
          })
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              console.log(response);
              Swal.fire("Password Change Successfully");
              // navigate(-1);
            } else {
              throw new Error(`Unexpected response status: ${response.status}`);
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
            // navigate(-1);
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Password not matched.",
        });
      }
      // };
      console.log("Passwords match and are valid!");
      handlePasswordModalClose();
    } catch (validationErrors) {
      // If validation fails, set the errors to be displayed
      const formErrors = {};
      validationErrors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      setErrors(formErrors);
    }
  };

  const handleFetchRows = async () => {
    try {
      const response = await axios.get(
        `/users/get-users?user_type=super_admin&user_id=${userDetails?._id}`
      );
      console.log(response.data);
      const data = response.data;
      setRows(data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const passwordSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  useEffect(() => {
    handleFetchRows();
  }, []);

  return (
    <Box>
      {/* Stack Layout for Search, Exposure, Profit, and Add Client Button */}
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems={isMobile ? "flex-start" : "center"}
        spacing={isMobile ? 2 : 0}
        mb={2}
      >
        {/* Search Field */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          size="small"
          fullWidth={isMobile}
        />

        {/* Exposure and Profit */}
        {!isMobile && (
          <>
            {/* <Typography>Total Exposure: 0.00</Typography>
            <Typography>Total Profit: 4,900.00</Typography> */}
          </>
        )}

        {/* Add Client Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{ height: "32px", width: isMobile ? "100%" : "auto" }}
        >
          Add Client
        </Button>
      </Stack>

      {/* Display Exposure and Profit below Search on Mobile */}
      {isMobile && (
        <Stack direction="column" spacing={1} mb={2}>
          {/* <Typography>Total Exposure: 0.00</Typography>
          <Typography>Total Profit: 4,900.00</Typography> */}
        </Stack>
      )}

      {/* Data Grid */}
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredRows.map((row) => ({
            ...row,
            handleCheckboxClick,
            handlePasswordChangeClick,
            handleLimitClick,
            handleSettingsClick,
          }))}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </div>

      {/* Add Client Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "50px auto",
            maxWidth: isMobile ? "90%" : 800,
            borderRadius: "15px",
          }}
        >
          <NewManageUserForm onClose={handleClose} userType={"Supreme Admin"} />
        </Box>
      </Modal>

      {/* Password Change Modal */}
      <Modal open={passwordModal.open} onClose={handlePasswordModalClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "50px auto",
            maxWidth: isMobile ? "90%" : 400,
            borderRadius: "15px",
          }}
        >
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <TextField
              label="New Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="dense"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={!!errors.newPassword}
              helperText={errors.newPassword}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="dense"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePasswordModalClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handlePasswordSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Modal>

      {/* Limit Modal */}
      <Modal open={limitModal.open} onClose={handleLimitModalClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "50px auto",
            maxWidth: isMobile ? "90%" : 550,
            borderRadius: "15px",
          }}
        >
          <MasterUserLimit
            onClose={handleLimitModalClose}
            userType={"User"}
            info={limitModal.info}
          />
        </Box>
      </Modal>

      {/* Settings Modal */}
      <Modal open={settingsModal.open} onClose={handleSettingsModalClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "50px auto",
            maxWidth: isMobile ? "90%" : 400,
            borderRadius: "15px",
          }}
        >
          <MasterUserSettings
            onClose={handleSettingsModalClose}
            userType={"User"}
          />
        </Box>
      </Modal>

      {/* DWC Modal */}
      <Modal open={openDwcModal} onClose={handleDwcModalClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "50px auto",
            maxWidth: isMobile ? "90%" : 800,
            borderRadius: "15px",
          }}
        >
          <DwcForm
            onClose={handleDwcModalClose}
            userType={"User"}
            activeTabValue={activeTab}
          />
        </Box>
      </Modal>

      {/* Confirmation Dialog */}
      <Dialog open={confirmDialog.open} onClose={() => handleConfirm(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleConfirm(true)} color="primary">
            Yes
          </Button>
          <Button onClick={() => handleConfirm(false)} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
