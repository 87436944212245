import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import axiosInstance from "../../helpers/axios";
import { Box, Button, Modal, Stack, TextField } from "@mui/material";
import NewClientForm from "./NewClientForm";
import EditIcon from "@mui/icons-material/Edit";
import PasswordIcon from "@mui/icons-material/Password";
import { IconButton } from "@mui/material";

const columns = [
  {
    field: "_id",
    headerName: "ID",
    width: 200, // Fixed width instead of flex
    disableColumnMenu: true,
  },
  {
    field: "username",
    headerName: "UserName",
    width: 150, // Adjust width as needed
    disableColumnMenu: true,
  },
  {
    field: "first_name",
    headerName: "First name",
    width: 150, // Adjust width as needed
    disableColumnMenu: true,
  },
  {
    field: "last_name",
    headerName: "Last name",
    width: 150, // Adjust width as needed
    disableColumnMenu: true,
  },
  {
    field: "fix_limit",
    headerName: "Fix Limit",
    type: "number",
    headerAlign: "start",
    align: "start",
    width: 120, // Adjust width as needed
    disableColumnMenu: true,
  },
  {
    field: "my_match_share",
    headerName: "My Share",
    description: "This column has a value getter and is not sortable.",
    width: 150, // Adjust width as needed
    disableColumnMenu: true,
    valueGetter: (value, row) => `${row?.my_match_share || ""}`,
  },
  // {
  //   field: "max_share",
  //   headerName: "Max Share",
  //   width: 150, // Adjust width as needed
  //   disableColumnMenu: true,
  //   valueGetter: (value, row) => `${row?.max_share || ""}`,
  // },
  {
    field: "exposure",
    headerName: "Exposure",
    width: 150, // Adjust width as needed
    disableColumnMenu: true,
    valueGetter: (value, row) => `${row?.exposure || "0"}`,
  },
  // {
  //   field: "actions",
  //   headerName: "Actions",
  //   width: 100,
  //   align: "center",
  //   disableColumnMenu: true,
  //   renderCell: (params) => (
  //     <div style={{ display: "flex", justifyContent: "space-between" }}>
  //       <IconButton color="primary" onClick={() => handleEdit(params.row)}>
  //         <EditIcon />
  //       </IconButton>
  //       <IconButton
  //         color="secondary"
  //         onClick={() => handleChangePassword(params.row)}
  //       >
  //         <PasswordIcon />
  //       </IconButton>
  //     </div>
  //   ),
  // },
];

function handleEdit(row) {
  // Handle edit user logic here
  console.log("Edit user:", row);
}

function handleChangePassword(row) {
  // Handle change password logic here
  console.log("Change password for user:", row);
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function MyClientsTable() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFetchRows = async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem("userProfile"));
      const response = await axiosInstance.get(
        `/api/users/getBlockedClient?_id=${userDetails?._id}`
      );
      console.log(response.data);
      const data = response.data;
      setRows(data?.dataobj);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddUser = (newUser) => {
    setRows([...rows, newUser]);
  };

  useEffect(() => {
    handleFetchRows();
  }, []);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          size="small"
        />
      </Stack>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
        <Modal open={open} onClose={handleClose}>
          <div
            style={{
              padding: 20,
              background: "white",
              margin: "50px auto",
              maxWidth: 800,
              borderRadius: "15px",
            }}
          >
            <NewClientForm onSubmit={handleAddUser} onClose={handleClose} />
          </div>
        </Modal>
      </div>
    </Box>
  );
}
