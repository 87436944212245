import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Modal, Stack, TextField, Container } from "@mui/material";
import axios from "../../helpers/axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";

export default function LiveMatches() {
  const params = useParams();
  const navigate = useNavigate();
  const [matches, setMatches] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabSport, setActiveTabSport] = useState(0);
  const [sports_id, setSports_id] = useState(4);

  useEffect(() => {
    const fetchData = async () => {
      if (activeTabSport === 0) {
        setSports_id(4);
        await getLiveMatches(sports_id);
      } else if (activeTabSport === 1) {
        setSports_id(1);
        await getLiveMatches(sports_id);
      } else if (activeTabSport === 2) {
        setSports_id(2);
        await getLiveMatches(sports_id);
      } else {
        await getUpcomingMatches(sports_id);
      }
    };

    fetchData();
  }, [activeTabSport, sports_id]);

  const getLiveMatches = async (sports_id) => {
    setMatches([]);
    try {
      let res = await axios.get("/matches/getLiveMatches/" + sports_id);
      if (res.data.status) {
        setMatches(res.data.dataobj);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUpcomingMatches = async (sports_id) => {
    setMatches([]);
    try {
      let res = await axios.get("/matches/getUpcommingMatches/" + sports_id);
      if (res.data.status) {
        setMatches(res.data.dataobj);
      }
    } catch (err) {
      console.log(err);
    }
  };
  function formatDateAndTime(dateString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const columns = [
    {
      field: "match_id",
      headerName: "ID",
      width: 100, // Adjust width as needed
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "match_name",
      headerName: "Name",
      width: 200, // Adjust width as needed
      disableColumnMenu: true,
      renderCell: (params) => (
        <Link
          to={`/live-matches/reports/${params.row.matchObj.id}/live-report`}
          style={{
            color: "#0080FF",
            display: "block",
            width: "100%",
            textOverflow: "ellipsis", // Ensure text does not overflow
            overflow: "hidden",
            whiteSpace: "nowrap", // Prevent wrapping
          }}
        >
          {params.row.match_name}
        </Link>
      ),
    },
    {
      field: "start_time",
      headerName: "Date",
      width: 150, // Adjust width as needed
      disableColumnMenu: true,
      renderCell: (params) => (
        <span style={{ width: "100%", textAlign: "center" }}>
          {formatDateAndTime(params.value)}
        </span>
      ),
    },
    {
      field: "profit_loss",
      headerName: "Profit / Loss",
      width: 150, // Adjust width as needed
      disableColumnMenu: true,
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 150, // Adjust width as needed
    //   disableColumnMenu: true,
    //   renderCell: (params) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         paddingTop: "12px",
    //         width: "100%",
    //       }}
    //     >
    //       <button
    //         style={{
    //           backgroundColor: "#9d2525",
    //           color: "white",
    //           border: "none",
    //           cursor: "pointer",
    //           padding: "5px 10px",
    //           textAlign: "center",
    //         }}
    //         onClick={() => {
    //           navigate(
    //             `/live-matches/reports/${params.row.matchObj.id}/live-report`
    //           );
    //         }}
    //       >
    //         Live Report
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  const rowsCricket = [
    // Cricket Matches
    {
      match_id: 1,
      match_name: "Team A vs Team B",
      start_time: "2024-09-30T10:00:00Z",
      profit_loss: "+$500",
      matchObj: { id: 1 },
    },
    {
      match_id: 2,
      match_name: "Team C vs Team D",
      start_time: "2024-10-01T12:00:00Z",
      profit_loss: "-$200",
      matchObj: { id: 2 },
    },
    {
      match_id: 3,
      match_name: "Team E vs Team F",
      start_time: "2024-10-02T14:30:00Z",
      profit_loss: "+$300",
      matchObj: { id: 3 },
    },
  ];

  const rowsSoccer = [
    // Cricket Matches
    {
      match_id: 4,
      match_name: "Team X vs Team Y",
      start_time: "2024-10-03T15:00:00Z",
      profit_loss: "+$250",
      matchObj: { id: 4 },
    },
    {
      match_id: 5,
      match_name: "Team Z vs Team W",
      start_time: "2024-10-04T17:00:00Z",
      profit_loss: "-$100",
      matchObj: { id: 5 },
    },
    {
      match_id: 6,
      match_name: "Team Q vs Team R",
      start_time: "2024-10-05T19:30:00Z",
      profit_loss: "+$400",
      matchObj: { id: 6 },
    },
  ];

  const rowsTennis = [
    // Cricket Matches
    {
      match_id: 7,
      match_name: " Player 1 vs Player 2",
      start_time: "2024-10-06T11:00:00Z",
      profit_loss: "+$600",
      matchObj: { id: 7 },
    },
    {
      match_id: 8,
      match_name: " Player 3 vs Player 4",
      start_time: "2024-10-07T13:30:00Z",
      profit_loss: "-$150",
      matchObj: { id: 8 },
    },
    {
      match_id: 9,
      match_name: " Player 5 vs Player 6",
      start_time: "2024-10-08T16:00:00Z",
      profit_loss: "+$350",
      matchObj: { id: 9 },
    },
  ];

  const returnCurrentTabTable = () => {
    if (activeTab === 0 && activeTabSport === 0)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Live Cricket Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row.match_id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab === 0 && activeTabSport === 1)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Live Soccer Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row.match_id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab === 0 && activeTabSport === 2)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Live Tennis Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row.match_id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab === 1 && activeTabSport === 0)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Upcoming Cricket Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab === 1 && activeTabSport === 1)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Upcoming Soccer Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else if (activeTab === 1 && activeTabSport === 2)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Upcoming Tennis Matches
          </div>

          <DataGrid
            rows={matches}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            autoHeight
            disableSelectionOnClick
          />
        </div>
      );
    else return <></>;
  };

  return (
    <div>
      <div style={{ overflow: "auto" }}>
        <div
          style={{
            padding: "16px 0",
            display: "grid",
            placeItems: "center",
            background: "",
            borderRadius: "8px",
            marginBottom: "8px",
          }}
        >
          {/* <Tabs
            value={activeTab}
            sx={{
              ".Mui-selected": {
                color: `#fff`,
                outline: "none",
              },
              "&:hover": {
                outline: "none",
              },
            }}
            TabIndicatorProps={{ style: { background: "#896cef" } }}
            aria-label="tabs example"
            onChange={(e, newVal) => {
              setActiveTab(newVal);
            }}
          >
            <Tab
              value={0}
              label={
                <span
                  style={{ color: activeTab === 0 ? "#896cef" : "#808080" }}
                >
                  In Play Matches
                </span>
              }
            />
            <Tab
              value={1}
              label={
                <span
                  style={{ color: activeTab === 1 ? "#896cef" : "#808080" }}
                >
                  Upcoming Matches
                </span>
              }
            />
          </Tabs> */}
        </div>
        <div
          style={{
            padding: "16px 0",
            display: "grid",
            placeItems: "center",
            background: "",
            borderRadius: "8px",
          }}
        >
          <Tabs
            value={activeTabSport}
            sx={{
              ".Mui-selected": {
                color: `#fff`,
                outline: "none",
              },
              "&:hover": {
                outline: "none",
              },
            }}
            TabIndicatorProps={{ style: { background: "#896cef" } }}
            aria-label="tabs example"
            onChange={(e, newVal) => {
              setActiveTabSport(newVal);
            }}
          >
            <Tab
              value={0}
              label={
                <span
                  style={{
                    color: activeTabSport === 0 ? "#896cef" : "#808080",
                  }}
                >
                  Cricket
                </span>
              }
            />
            <Tab
              value={1}
              label={
                <span
                  style={{
                    color: activeTabSport === 1 ? "#896cef" : "#808080",
                  }}
                >
                  Soccer
                </span>
              }
            />
            <Tab
              value={2}
              label={
                <span
                  style={{
                    color: activeTabSport === 2 ? "#896cef" : "#808080",
                  }}
                >
                  Tennis
                </span>
              }
            />
          </Tabs>
        </div>
        {returnCurrentTabTable()}
      </div>
    </div>
  );
}
