import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NewManageUserForm from "../partials/NewManageUserForm";
import axiosInstance from "../../../helpers/axios";

const columns = [
  { field: "_id", headerName: "ID", width: 200, disableColumnMenu: true },
  {
    field: "username",
    headerName: "UserName",
    width: 130,
    disableColumnMenu: true,
  },
  {
    field: "first_name",
    headerName: "Name",
    width: 130,
    disableColumnMenu: true,
  },
  {
    field: "fix_limit",
    headerName: "Fix Limit",
    type: "number",
    width: 90,
    disableColumnMenu: true,
  },
  {
    field: "my_match_share",
    headerName: "My Share",
    description: "This column has a value getter and is not sortable.",
    width: 160,
    disableColumnMenu: true,
    valueGetter: (value, row) => `${row?.my_match_share || ""}`,
  },
  // {
  //   field: "max_share",
  //   headerName: "Max Share",
  //   width: 160,
  //   disableColumnMenu: true,
  //   valueGetter: (value, row) => `${row?.max_share || ""}`,
  // },
  {
    field: "exposure",
    headerName: "Exposure",
    width: 160,
    disableColumnMenu: true,
    valueGetter: (value, row) => `${row?.exposure || "0"}`,
  },
];

export default function ManageSuperAdminTable() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const userType = localStorage.getItem("user_type");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Define breakpoints for responsiveness

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFetchRows = async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem("userProfile"));
      const response = await axiosInstance.get(
        `/users/get-users?user_type=super_admin&user_id=${userDetails?._id}`
      );
      console.log(response.data);
      const data = response.data;
      setRows(data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddUser = (newUser) => {
    setRows([...rows, newUser]);
  };

  useEffect(() => {
    handleFetchRows();
  }, []);

  return (
    <Box>
      <Stack
        direction={isMobile ? "column" : "row"} // Stack vertically on mobile, row on desktop
        justifyContent="space-between"
        alignItems={isMobile ? "stretch" : "center"} // Stretch items on mobile
        spacing={isMobile ? 2 : 0}
        mb={2}
      >
        {/* Search Bar */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          size="small"
          fullWidth={isMobile} // Full width on mobile for better usability
        />

        {/* Add Super Admin Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{
            height: "32px",
            width: isMobile ? "100%" : "auto", // Full width on mobile
          }}
        >
          Add Super Admin
        </Button>
      </Stack>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </div>

      {/* Add Super Admin Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "50px auto",
            maxWidth: isMobile ? "90%" : 800, // Adjust modal width on mobile
            borderRadius: "15px",
          }}
        >
          <NewManageUserForm onClose={handleClose} userType={"Supreme Admin"} />
        </Box>
      </Modal>
    </Box>
  );
}
