import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  MenuItem,
  Typography,
  Snackbar,
  Alert,
  Select,
  InputLabel,
} from "@mui/material";
import sha256 from "sha256";
import { useNavigate } from "react-router-dom";
import axios from "../../../helpers/axios";

const NewManageUserForm = ({ onClose, userType, initialValues }) => {
  const userDetails = JSON.parse(localStorage.getItem("userProfile"));
  const [user_type, setUserType] = useState("");
  const [popUpShow, setPopUpShow] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: initialValues?.username || "",
      password: "",
      confirmPassword: "",
      first_name: initialValues?.first_name || "",
      last_name: initialValues?.last_name || "",
      fix_limit: initialValues?.fix_limit || "",
      my_match_share: initialValues?.my_match_share || "",
      match_commission: initialValues?.match_commission || "",
      other_match_share: initialValues?.other_match_share || "",
      session_commission: initialValues?.session_commission || "",
      phone: "",
      userType: "User",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      password: !initialValues
        ? Yup.string().required("Password is required")
        : Yup.string(),
      confirmPassword: !initialValues
        ? Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required")
        : Yup.string(),
      first_name: Yup.string().required("First Name is required"),
      // last_name: Yup.string().required("Last Name is required"),
      fix_limit: Yup.number().required("Fix Limit is required").min(0),
      my_match_share: Yup.number()
        .required("Match Share is required")
        .min(0)
        .max(userDetails.my_match_share),
      match_commission: Yup.number()
        .required("Match Commission is required")
        .min(0)
        .max(2),
      other_match_share: Yup.number()
        .required("Other Match Share is required")
        .min(0)
        .max(100),
      session_commission: Yup.number()
        .required("Session Commission is required")
        .min(0)
        .max(2),
      // phone: Yup.string().required("Phone number is required"),
    }),
    onSubmit: (values) => {
      // if (userType === "Supreme Admin") {
      //   setUserType("super_admin");
      // } else if (userType === "user") {
      //   setUserType("User");
      // } else if (userType === "Admin") {
      //   setUserType("admin");
      // } else if (userType === "Stokist") {
      //   setUserType("stokist");
      // } else if (userType === "Agent") {
      //   setUserType("agent");
      // } else if (userType === "Super Stokist") {
      //   setUserType("super_stokist");
      // }
      const { confirmPassword, ...userData } = values;
      // Encrypt the password using SHA256
      const encryptedPassword = sha256(values.password).toString();
      const finalUserData = {
        ...userData,
        password: encryptedPassword,
        parent_id: userDetails._id,
        user_type: userData.user_type,
        credits: "0",
      };

      if (values.password === values.confirmPassword) {
        axios
          .post("/users/add-user", { ...finalUserData })
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              setSnackbarMessage("User created successfully!");
              setSnackbarSeverity("success");
              setSnackbarOpen(true);
              navigate(-1);
            } else {
              throw new Error(`Unexpected response status: ${response.status}`);
            }
          })
          .catch((error) => {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          });
      } else {
        setSnackbarMessage("Password and Confirm Password do not match");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    },
  });

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleMyMatchShareChange = (e) => {
    let myMatchShareValue = 0;
    if (e.target.value <= userDetails?.my_match_share) {
      myMatchShareValue = e.target.value;
    } else {
      myMatchShareValue = userDetails?.my_match_share;
    }

    // Set the value of 'my_match_share'
    formik.setFieldValue("my_match_share", myMatchShareValue);

    const calculatedMatchCommission =
      userDetails.my_match_share - myMatchShareValue;
    formik.setFieldValue("other_match_share", calculatedMatchCommission);
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ maxHeight: "80vh", overflowY: "auto", p: 2 }}
    >
      <Typography variant="h6" sx={{ mb: 3 }}>
        New {userType}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Select
            labelId="userType-label"
            id="userType"
            fullWidth
            name="userType"
            variant="outlined"
            placeholder="User Type"
            value={formik.values.userType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.userType && Boolean(formik.errors.userType)}
          >
            <MenuItem value="User">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="super_admin">Super Admin</MenuItem>
            <MenuItem value="agent">Agent</MenuItem>
          </Select>
          {formik.touched.userType && formik.errors.userType && (
            <p style={{ color: "red" }}>{formik.errors.userType}</p>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="username"
            name="username"
            placeholder="Username"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
        </Grid>
        {!initialValues ? (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                variant="outlined"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                variant="outlined"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="first_name"
            name="first_name"
            placeholder="Full Name"
            variant="outlined"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
            }
            helperText={formik.touched.first_name && formik.errors.first_name}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="last_name"
            name="last_name"
            placeholder="Last Name"
            variant="outlined"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
          />
        </Grid> */}

        {/* <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="phone"
            name="phone"
            placeholder="Phone"
            variant="outlined"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="fix_limit"
            name="fix_limit"
            type="number"
            placeholder="Fix Limit"
            variant="outlined"
            value={formik.values.fix_limit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.fix_limit && Boolean(formik.errors.fix_limit)}
            helperText={formik.touched.fix_limit && formik.errors.fix_limit}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="my_match_share"
            name="my_match_share"
            type="number"
            placeholder="My Match Share"
            variant="outlined"
            value={formik.values.my_match_share}
            onChange={handleMyMatchShareChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.my_match_share &&
              Boolean(formik.errors.my_match_share)
            }
            helperText={
              formik.touched.my_match_share && formik.errors.my_match_share
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="other_match_share"
            name="other_match_share"
            type="number"
            placeholder={`${userType} Match Share`}
            variant="outlined"
            value={formik.values.other_match_share}
            disabled
            error={
              formik.touched.other_match_share &&
              Boolean(formik.errors.other_match_share)
            }
            helperText={
              formik.touched.other_match_share &&
              formik.errors.other_match_share
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="match_commission"
            name="match_commission"
            type="number"
            placeholder={`${userType} Match Commission`}
            variant="outlined"
            value={formik.values.match_commission}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.match_commission &&
              Boolean(formik.errors.match_commission)
            }
            helperText={
              formik.touched.match_commission && formik.errors.match_commission
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="session_commission"
            name="session_commission"
            type="number"
            placeholder={`${userType} Session Commission`}
            variant="outlined"
            value={formik.values.session_commission}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.session_commission &&
              Boolean(formik.errors.session_commission)
            }
            helperText={
              formik.touched.session_commission &&
              formik.errors.session_commission
            }
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "flex-end",
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          sx={{ mb: 2, sm: { mr: 2 } }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{
            mb: 2,
            ml: 2,
            backgroundColor: "#735CFF",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#5c49cc",
            },
          }}
        >
          Submit
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewManageUserForm;
