import React, { useState, useMemo, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  MenuItem,
  Stack,
  TextField,
  Select,
  InputLabel,
  FormControl,
  Container,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"; // Import LocalizationProvider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; // Import the dayjs adapter
import axiosInstance from "../helpers/axios";
// import dayjs from "dayjs";

const accountTypes = ["All", "Balance Report", "Game Report"];
const gameNames = ["All", "Cricket", "Soccer", "Tennis"];
const clients = ["Super Admin", "Admin", "User", "Agent"];
// Mock data with consistent types
const dummyData = [
  {
    id: 1,
    date: "2024-09-01", // ISO date string
    username: "user1",
    credit: 100,
    debit: 50,
    description: "Game Win",
    closing: 50,
  },
  {
    id: 2,
    date: "2024-09-02",
    username: "user2",
    credit: 200,
    debit: 100,
    description: "Game Loss",
    closing: 100,
  },
  {
    id: 3,
    date: "2024-09-03",
    username: "user3",
    credit: 150,
    debit: 75,
    description: "Balance Update",
    closing: 75,
  },
];

const columns = [
  { field: "date", headerName: "Date", width: 130, sortable: true },
  { field: "username", headerName: "Username", width: 130, sortable: true },
  { field: "credit", headerName: "Credit", width: 100, sortable: true },
  { field: "debit", headerName: "Debit", width: 100, sortable: true },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    sortable: true,
  },
  // { field: "closing", headerName: "Closing", width: 100, sortable: true },
];

export default function AccountStatementReports() {
  const [accountType, setAccountType] = useState("All");
  const [gameName, setGameName] = useState("All");
  const [client, setClient] = useState("Super Admin");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const handleAccountTypeChange = (event) => setAccountType(event.target.value);
  const handleGameNameChange = (event) => setGameName(event.target.value);
  const handleClientChange = (event) => setClient(event.target.value);
  const handleSearchTermChange = (event) => setSearchTerm(event.target.value);
  const [rows, setRows] = useState([]);

  // Filtering function based on search term and selected filters
  const filteredRows = useMemo(() => {
    return dummyData.filter((row) => {
      const matchSearchTerm = Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );
      const matchAccountType =
        accountType === "All" || row.description.includes(accountType);
      const matchGameName =
        gameName === "All" || row.description.includes(gameName);
      const matchClient = client === "All" || row.username.includes(client);

      return (
        matchSearchTerm && matchAccountType && matchGameName && matchClient
      );
    });
  }, [searchTerm, accountType, gameName, client]);

  const handleFetchRows = async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem("userProfile"));
      const response = await axiosInstance.get(
        `/users/getUserStatement?user_id=${userDetails?._id}`
      );
      console.log(response.data);
      const data = response.data;
      setRows(data?.dataobj);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleFetchRows();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* Wrap component with LocalizationProvider */}
      <Container maxWidth="lg">
        {" "}
        {/* Change maxWidth to lg for larger screens */}
        <Box padding={2}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="flex-start"
            mb={2}
          >
            <FormControl fullWidth sx={{ minWidth: 150 }}>
              <InputLabel>Account Type</InputLabel>
              <Select
                value={accountType}
                label="Account Type"
                onChange={handleAccountTypeChange}
                size="small"
              >
                {accountTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ minWidth: 150 }}>
              <InputLabel>Game Name</InputLabel>
              <Select
                value={gameName}
                label="Game Name"
                onChange={handleGameNameChange}
                size="small"
              >
                {gameNames.map((game) => (
                  <MenuItem key={game} value={game}>
                    {game}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ minWidth: 150 }}>
              <InputLabel>Search By Client</InputLabel>
              <Select
                label="Search By Client"
                value={client}
                onChange={handleClientChange}
                size="small"
              >
                {clients.map((clientType) => (
                  <MenuItem key={clientType} value={clientType}>
                    {clientType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchTermChange}
              size="small"
              fullWidth
              sx={{ minWidth: 150 }}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} mb={2}>
            <DatePicker
              label="From"
              variant="outlined"
              sx={{ minWidth: 150 }}
              value={fromDate}
              onChange={(newDate) => setFromDate(newDate)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />

            <DatePicker
              label="To"
              value={toDate}
              onChange={(newDate) => setToDate(newDate)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Stack>

          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              getRowId={(row) => row._id}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10]}
              // checkboxSelection
            />
          </div>
        </Box>
      </Container>
    </LocalizationProvider>
  );
}
